@import './variables';
@import './loader';
@import './signupAndLogin';
@import './dashboard';
@import './header';
@import './sidebar';
@import './agenda';
@import './errorScreen';
@import './ideas';
@import './editTask';
@import './actionPlan';
@import './usersAdministration';
@import './ideas';
@import './editBestContribution';
@import './textbox';
@import './attendance';

html, body, #root {
    height: 100%;
    overflow: hidden;

    ::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: $primary;
        border-radius: 20px;
    }

    .border-color-primary {

        &:active {
             border-color: $primary;
        }

        &:focus {
             border-color: $primary;
        }
    }
}